<template>
  <div>
<!--导航-->
  <div><van-nav-bar
  title="商品分类"
  left-arrow
  @click-left="$router.back(-1)"
  :fixed="true"
  ></van-nav-bar>
  </div>
<!--标签-->
<div style="margin-top:50px">
  <!--商品列表-->
  <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
  <div class="card-goods-goods"   v-for="item in goods"  :key="item.id"
   :name="item.id" >
   <van-card
          price="10.00" :thumb="item.cover_image" 
       @click="OnDetail(item)">
         <div slot="title">
           <span  class="p1" ><img  :src="item.platform_icon" 
           style="width:15px;height:15px">{{item.title}}</span>
  </div>
    <div slot="desc">
    <span class="word4-goods"   style="display:block"
    @click="OnDetail(item)">¥{{item.discount_price}}</span>
   <span class="info-goods" >{{item.shop_name}}</span>
  <div><span class="info-goods">原价</span><span  class="info-goods" style="text-decoration:line-through">¥{{item.price}}</span>
      <span class="info-goods" style="  margin: 6px 0 0 7px;">已售{{item.month_sales}}</span></div>
      <div style="margin-top:10px">
       <span class="span1-goods">
        <span class="word10-goods">券</span>
      <span class="word10-goods">¥{{item.coupon_money}}</span>
      </span>
      <span  style=" font-size: 10px; color: rgba(255, 0, 0, 1);background-color: rgba(254, 240, 240, 1); margin-left:10px">分享赚¥{{item.makeup}}</span>
      
      </div>
 
  </div>
  <div slot="price" > 
  </div>
  <!-- <div slot="bottom" style="margin-top:0px">
      <div><span class="info-goods">原价</span><span  class="info-goods" style="text-decoration:line-through">¥{{item.price}}</span>
      <span class="info-goods" style="  margin: 6px 0 0 7px;">已售{{item.month_sales}}</span></div>
      <div><span class="span1-goods">  
       <span class="word10-goods">券</span>
       <span class="word10-goods">¥{{item.coupon_money}}</span>    </span>
        <span  style=" font-size: 10px; color: rgba(255, 0, 0, 1);background-color: rgba(254, 240, 240, 1); margin-left:20px">分享赚¥{{item.fl_commission_amount}}</span></div>
      </div>-->
        </van-card>
      </div>
  </van-list>
      </div>
 
  </div>
</template>

<script>
import { Grid,DropdownMenu} from 'vant';
import {getChildList} from '@/api/goods/goods'
//import lineList from '@/views/linegoodsdetail/index.vue'
//import goodsListDetail from '@/views/listgoodsdetail/index.vue'
export default {
   components: {
     //lineList,
    // goodsListDetail,
    [Grid.name]: Grid,
     [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
    loading: false,
    finished: false,

    total: 0,//总共的数据条数
       page: {
          currentPage: 1, // 当前页数
          pageSize: 10, // 每页显示多少条
        },
      goods: [],
      platformId:''
    };
  },


mounted()
{
  this.platformId=this.$route.query.type
  this.getChildListData();
},
  methods: {
      onLoad() {
      this.page.currentPage++;
      this.getChildListData();
    },
  getChildListData()
  {
  let params ={'categoryId': this.$route.query.categoryId}
  const object2 = Object.assign({page: this.page.currentPage, pageSize: this.page.pageSize}, params);
  getChildList(object2).then((res) => {
  let rows = res.data.data.data.data; //请求返回当页的列表
    // 将新数据与老数据进行合并
         if(res.data.code===0)
         {
            this.goods = this.goods.concat(rows);
         }
          //如果列表数据条数>=总条数，不再触发滚动加载
        this.loading = false;
        //alert(JSON.stringify(this.total))
        if (rows == null || rows.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        if (this.goods.length>=200) {
            this.finished = true;
        }
  //this.goods=res.data.data.data.data
  //alert(JSON.stringify(this.goods))
                }).catch(err=>{
                        console.log(err)   
                    }
                )
},
 OnDetail(item)
{
  let params={'Id':item.item_id,'platformId':item.platform_id,'type':'0'}
  
  if(this.$store.state.session!=null&&this.$store.state.session!='')
  {
    let params1={'session':this.$store.state.session}
    params=Object.assign(params,params1)
  }
  if(this.$store.state.token!=null)
  {
     let params1={'token':this.$store.state.token}
     params=Object.assign(params,params1)
  }
  if(this.$store.state.empower!=null&&this.$store.state.empower!='0')
  {
     let params1={'empower':this.$store.state.empower}
     params=Object.assign(params,params1)
  }
   this.$router.push({name:'Goodsdetails',query:params})
},
    onSubmit() {
      //Toast('点击结算');
    },
 
  }
};
</script>

<style lang="less" scoped>
   .info-goods {
  // overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height:5px;
  text-align: left;
  // height: 10px;
}
.span1-goods
{
  background: url(/img/youhui-1.png)
   100% no-repeat;
	width: 46px;
  height:18px; 
	/* 
	使用% 设置宽高以适应父元素的大小
	因为可能得高度坍塌，使用padding-top=（原图高/原图宽）设置高度
	*/
	background-size: cover;
	background-position: center;
	/*
	使用 background-size: cover; 自适应铺满
	background-position: center; 兼容不支持上面语句的浏览器版本
	*/
	display: inline-block;
}
.word10-goods {
  line-height: 10px;
  margin-left: 5px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  text-align: center;
}
.word4-goods {
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 15px;
  text-align: left;
  margin-left: 1px;
}
.word-goods {
  left: 11px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
}
.van-card__thumb {
    position: relative;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    width: 133px;
    height: 133px;
    margin-right: 8px;
}
.p1{
//text-align: center;
//height:34px;
font-size:17px;margin-left:5px;
text-overflow: -o-ellipsis-lastline;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.card-goods-goods
{
  margin-top:5px;
  margin-left: 1%;
  margin-right: 1%;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
.van-card__price {
  display: block;
}
</style>
